<template>
  <div class="help">
    <h1>Help</h1>
    <div class="help-main">
      <h3>An oversimplified view a Project in the P2020 UI</h3>
      <p>
        The main purpose of the P2020 UI is for the production team to manage and observe their data processing procedures.
        The benefit of this User Interface is that the team can get a high level view of how their data processing is going and displays that in a helpful way.
      </p>
      <p>To understand the UI it helps to understand how all the pieces fit together.</p>
      <p>In P2020 terms a <strong>Workflow</strong> is a set of instructions to accomplish a particular goal.</p>
      <p>In order to complete this it divides that tasks into specific <strong>steps</strong>. Each step contains a list of <strong>Jobs</strong> where a job completes one of many similar operations.</p>
      <img src="../assets/P2020.png"/>
      <div>Below is a comprehensive guide as to how the full application works.</div>
      <a :href='"https://surfaceintelligence.atlassian.net/wiki/spaces/PIP/pages/2428764173/Pipeline+2020+building+blocks+including+a+glossary+of+terms"' target="#">Pipeline 2020 Building Blocks</a>
  
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
}

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.help{
  width: 60%;
  justify-content: center;
  margin: 1rem auto;
}

.help-main{
  width: 50%;
  justify-content: center;
  margin: 1rem auto;
}

</style>
